<template>
    <div>
        <van-overlay :show="show">
            <van-loading type="spinner" />
        </van-overlay>
    </div>
</template>

<script>
export default {
    name: 'Loading',
    data() {
        return {
            show: true
        }
    }
}
</script>