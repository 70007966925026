import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/OrderDetails',
    name: 'OrderDetails',
    meta: {
      title: '订单详情'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "OrderDetails" */ '../views/mobile/OrderDetails.vue')
  },
  {
    path: '/GoodsDetails',
    name: 'GoodsDetails',
    meta: {
      title: '商品详情'
    },
    component: () => import(/* webpackChunkName: "GoodsDetails" */ '../views/mobile/GoodsDetails.vue')
  },
  {
    path: '/LoadGoods',
    name: 'LoadGoods',
    meta: {
      title: '装货信息'
    },
    component: () => import(/* webpackChunkName: "LoadGoods" */ '../views/mobile/LoadGoods.vue')
  },
  {
    path: '/introduction',
    name: 'Introduction',
    meta: {
      title: '平台介绍'
    },
    component: () => import(/* webpackChunkName: "Introduction" */ '../views/introduction/index.vue')
  },
  {
    path: '/standardization',
    name: 'Standardization',
    meta: {
      title: '标准化体系'
    },
    component: () => import(/* webpackChunkName: "Standardization" */ '../views/standardization/index.vue')
  },
  /* 帮助中心: 采购端 */
  {
    path: '/help/shop',
    name: 'HelpShop',
    meta: {
      title: '帮助中心'
    },
    component: () => import(/* webpackChunkName: "HelpShop" */ '../views/help/shop.vue')
  },
  /* 帮助中心: 苗圃端 */
  {
    path: '/help/supplier',
    name: 'HelpSupplier',
    meta: {
      title: '帮助中心'
    },
    component: () => import(/* webpackChunkName: "HelpSupplier" */ '../views/help/supplier.vue')
  },
  {
    path: '/doc/privacy',
    name: 'Privacy',
    meta: {
      title: '隐私政策'
    },
    component: () => import(/* webpackChunkName: "Privacy" */ '../views/doc/privacy.vue')
  },
  {
    path: '/doc/service',
    name: 'Service',
    meta: {
      title: '用户服务协议'
    },
    component: () => import(/* webpackChunkName: "Service" */ '../views/doc/service.vue')
  },
  {
    path: '/doc/terms',
    name: 'Terms',
    meta: {
      title: '使用条款及支付协议'
    },
    component: () => import(/* webpackChunkName: "Terms" */ '../views/doc/terms.vue')
  },
  /* 供应商欢迎入驻页面(无登录按钮) */
  {
    path: '/attraction/supplier',
    name: 'SupplierAttraction',
    meta: {
      title: '欢迎入驻苗仓地被直采中心'
    },
    component: () => import(/* webpackChunkName: "SupplierAttraction" */ '../views/attraction/supplier.vue')
  },
  /* 供应商欢迎入驻页面(有登录按钮) */
  {
    path: '/attraction/supplier-login',
    name: 'SupplierAttractionLogin',
    meta: {
      title: '欢迎入驻苗仓地被直采中心'
    },
    component: () => import(/* webpackChunkName: "SupplierAttractionLogin" */ '../views/attraction/supplierLogin.vue')
  },
  /* 司机欢迎入驻页面(无登录按钮) */
  {
    path: '/attraction/driver',
    name: 'DriverAttraction',
    meta: {
      title: '欢迎入驻苗仓地被直采中心'
    },
    component: () => import(/* webpackChunkName: "DriverAttraction" */ '../views/attraction/driver.vue')
  },
  /* 司机欢迎入驻页面(有登录按钮) */
  {
    path: '/attraction/driver-login',
    name: 'DriverAttractionLogin',
    meta: {
      title: '欢迎入驻苗仓地被直采中心'
    },
    component: () => import(/* webpackChunkName: "DriverAttractionLogin" */ '../views/attraction/driverLogin.vue')
  },
  /* 默认的欢迎入驻页面 */
  {
    path: '/attraction/default',
    name: 'DefaultAttraction',
    meta: {
      title: '欢迎入驻苗仓地被直采中心'
    },
    component: () => import(/* webpackChunkName: "DefaultAttraction" */ '../views/attraction/default.vue')
  },
  {
    path: '/cooperation/supplier',
    name: 'SupplierCooperation',
    meta: {
      title: '供应商入驻合作协议'
    },
    component: () => import(/* webpackChunkName: "SupplierCooperation" */ '../views/cooperation/supplier.vue')
  },
  {
    path: '/cooperation/driver',
    name: 'DriverCooperation',
    meta: {
      title: '货物委托运输服务合作协议'
    },
    component: () => import(/* webpackChunkName: "DriverCooperation" */ '../views/cooperation/driver.vue')
  },
  {
    path: '/green/introduction',
    name: 'GreenIntroduction',
    meta: {
      title: '"绿美广东" 栏目介绍'
    },
    component: () => import(/* webpackChunkName: "GreenIntroduction" */ '../views/green/introduction.vue')
  },
  {
    path: '/green/ebook',
    name: 'GreenEbook',
    meta: {
      title: '横栏花木采购指南'
    },
    component: () => import(/* webpackChunkName: "Test" */ '../views/green/ebook.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title == undefined?'苗仓地被直采中心':to.meta.title
  next()
})

export default router
