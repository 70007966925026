<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <div>主入口</div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    // HelloWorld
  }
}
</script>
