import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import {
  Button,
  Swipe,
  SwipeItem,
  Lazyload,
  Overlay,
  Loading,
  Collapse,
  CollapseItem,
  ImagePreview,
  ActionSheet,
  Dialog,
  Empty,
  Slider,
  Image
} from 'vant';
Vue
  .use(Button)
  .use(Swipe)
  .use(SwipeItem)
  .use(Lazyload)
  .use(Overlay)
  .use(Loading)
  .use(Collapse)
  .use(CollapseItem)
  .use(ImagePreview)
  .use(ActionSheet)
  .use(Dialog)
  .use(Empty)
  .use(Slider)
  .use(Image)
;

// 微信开放标签忽略报错
Vue.config.ignoredElements = [
  "wx-open-launch-weapp",
  "wx-open-launch-app",
  "wx-open-subscribe",
  "wx-open-audio",
];

// 绑定 jquery
window.$ = window.jQuery = require("jquery");

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
